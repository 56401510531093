<div class="row w-100">
  <div class="col-lg-12">
    <div class="card fhDetailsRowStyle">
      <div class="card-body">
        <div class="label-title">What is FH?</div>
        <p class="fhDetailsText">FH or Familial Hypercholesterolemia is a genetic condition that occurs due to inherited changes in a person’s DNA, known as ‘mutations’ or ‘variants.’ FH can result in altered processing of low-density lipoprotein (LDL) cholesterol also known as ‘bad cholesterol’. This altered processing of LDL-cholesterol results in very high blood cholesterol levels early in life.</p>
        <p class="fhDetailsText">Increased blood cholesterol levels can predispose individuals to early-onset of coronary heart disease (CHD), that is, in men before age 55 and in women before age 65. CHD can cause chest pain, shortness of breath and heart attack.</p>
        <div><h1>FH should be considered when:</h1></div>
        <ul>
          <li class="fhDetails-li">An adult has LDL-cholesterol above 190 mg/dL.</li>
          <li class="fhDetails-li">A child has LDL-cholesterol above 160 mg/dL.</li>
          <li class="fhDetails-li">A personal or family history of early-onset CHD is noted.</li>
        </ul>
        <p class="fhDetailsText">The Table below shows LDL-cholesterol values and what they mean for adults with no history of heart disease. If you have a history of heart disease, please consult with your healthcare provider for your optimal LDL-cholesterol value.</p>
        <img class="fh-details-tbl-img" src="./dist/assets/images/ldl_cholesterol_tbl.jpg" alt="Table">
      </div>
    </div>
  </div>
</div>     
    

    
    