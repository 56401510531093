
<div class="row w-100">
  <div class="col-lg-12">
      <div class="card fhDetailsRowStyle">
        <div class="card-body">
          <div class="label-title">What Causes FH?</div>
          <ul>
            <li class="fhDetails-li">FH occurs when there is a ‘pathogenic’ variant in one of three genes: the <span class="geneText">LDLR</span> gene, <span class="geneText">APOB</span> gene and <span class="geneText">PCSK9</span> gene.</li>
            <li class="fhDetails-li">The gene variant is passed on in an ‘autosomal dominant’ manner which means that one copy of the gene variant is enough to cause disease.</li>
            <li class="fhDetails-li">One copy of the altered gene can be passed from an affected parent to 50% or half of their children.</li>
            <li class="fhDetails-li">Therefore, it is important to inform family members of their risk for FH; this will enable them to talk to their healthcare providers and get screened for FH.</li>
          </ul>
          <p class="fhDetailsText">Each of the three genes has a different mechanism of action that alters the processing of LDL-cholesterol in the body:</p>
          <ul class="list-group">
            <li class="list-group-item label" >
              <a class="pls-icon" (click)="isLdlrCollapsed = !isLdlrCollapsed; ldlrClicked()">
                <span *ngIf="!isLdlrCollapsed">+ </span><span *ngIf="isLdlrCollapsed">- </span>
              </a>
              <span class="cursorStyle" (click)="isLdlrCollapsed = !isLdlrCollapsed; ldlrClicked()">LDLR</span>          
            </li>
            <div *ngIf="isLdlrCollapsed">
              <p class="fhDetailsTextCollapsable">LDL-cholesterol binds to a molecule called <span class="geneText">LDLR</span> which is a ‘receptor.’ Receptor molecules are located on the surfaces of liver cells, and when they bind LDL-cholesterol, they remove it from the blood. A pathogenic variant in the <span class="geneText">LDLR</span> gene prevents the receptor protein from carrying out its normal function and, as a result, blood levels of LDL-cholesterol increase.</p>
            </div>
          </ul>
          <ul class="list-group">
            <li class="list-group-item label" >
              <a class="pls-icon" (click)="isApobCollapsed = !isApobCollapsed; apobClicked()">
                <span *ngIf="!isApobCollapsed">+ </span><span *ngIf="isApobCollapsed">- </span>
              </a>
              <span class="cursorStyle" (click)="isApobCollapsed = !isApobCollapsed; apobClicked()">APOB</span>          
            </li>
            <div *ngIf="isApobCollapsed">
              <p class="fhDetailsTextCollapsable"><span class="geneText">APOB</span> protein is a carrier of LDL-cholesterol in the blood and binds to <span class="geneText">LDLR</span>. A pathogenic variant in the <span class="geneText">APOB</span> gene prevents the <span class="geneText">APOB</span> protein from binding properly to the LDL receptors on the surface of liver cells resulting in a buildup of LDL-cholesterol in the blood.</p>
            </div>
          </ul>
          <ul class="list-group">
            <li class="list-group-item label" >
              <a class="pls-icon" (click)="isPcsk9Collapsed = !isPcsk9Collapsed; pcsk9Clicked()">
                <span *ngIf="!isPcsk9Collapsed">+ </span><span *ngIf="isPcsk9Collapsed">- </span>
              </a>
              <span class="cursorStyle" (click)="isPcsk9Collapsed = !isPcsk9Collapsed; pcsk9Clicked()">PCSK9</span>          
            </li>
            <div *ngIf="isPcsk9Collapsed">
              <p class="fhDetailsTextCollapsable"><span class="geneText">PCSK9</span> protein is responsible for breaking down the LDL receptor molecule. A pathogenic variant in the <span class="geneText">PCSK9</span> gene results in increased activity of the <span class="geneText">PCSK9</span> protein, causing increased breakdown of the LDL receptor molecule. This, in turn, causes elevated LDL-cholesterol in the blood since there are fewer receptor molecules for it to bind to.</p>
            </div>
          </ul>
        </div>
      </div>
  </div>
</div>

    
    