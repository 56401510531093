/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./learn.component.ngfactory";
import * as i2 from "./learn.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/router";
import * as i5 from "../shared/common/mayo.service";
import * as i6 from "../shared/common/toastr.service";
import * as i7 from "../shared/common/jQuery.service";
import * as i8 from "./print.component";
var styles_PrintComponent = [];
var RenderType_PrintComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrintComponent, data: {} });
export { RenderType_PrintComponent as RenderType_PrintComponent };
export function View_PrintComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "learn-component", [], null, null, null, i1.View_LearnComponent_0, i1.RenderType_LearnComponent)), i0.ɵdid(1, 4308992, null, 0, i2.LearnComponent, [i3.Title, i4.Router, i5.MayoService, i4.ActivatedRoute, i6.TOASTR_TOKEN, i7.JQ_TOKEN], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "resources-component", [], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_PrintComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "print-component", [], null, null, null, View_PrintComponent_0, RenderType_PrintComponent)), i0.ɵdid(1, 4308992, null, 0, i8.PrintComponent, [i3.Title, i4.Router, i5.MayoService, i6.TOASTR_TOKEN, i7.JQ_TOKEN], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrintComponentNgFactory = i0.ɵccf("print-component", i8.PrintComponent, View_PrintComponent_Host_0, {}, {}, []);
export { PrintComponentNgFactory as PrintComponentNgFactory };
