﻿<main role="main" class="bg-light mainClass">
  <div class="row">
    <div class="col-md-4 col-lg-4 col-sm-12 text-left" >
      <app-learn-layout></app-learn-layout>
    </div>
    <div class="col-md-8 col-lg-8 col-sm-12 text-left">
      <router-outlet></router-outlet>
    </div>  
   
  </div>
</main>