import { Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { LearnComponent } from './learn.component';
import { ShareComponent } from './share.component';
import { ContactComponent } from './contact.component';
import { PrintComponent } from './print.component';
import { Toastr } from '../shared/common/index';
import { CustomerSupportComponent } from "./customer-support.component";
import { ToolsComponent } from "./tools.component";
import { LearnGeneticTestingFaqsComponent } from "./learn-genetic-testing-faqs/learn-genetic-testing-faqs.component";
import { LearnWhatIsFhComponent } from "./learn-what-is-fh/learn-what-is-fh.component";
import { LearnFhConsiderationsComponent } from "./learn-fh-considerations/learn-fh-considerations.component";
import { LearnGeneticsComponent } from "./learn-genetics/learn-genetics.component";
import { LearnWhatCausesFhComponent } from "./learn-what-causes-fh/learn-what-causes-fh.component";
import { LearnFhTitleComponent } from "./learn-fh-title/learn-fh-title.component";
import { LearnFhRisksComponent } from "./learn-fh-risks/learn-fh-risks.component";
import { LearnFhSymptomsComponent } from "./learn-fh-symptoms/learn-fh-symptoms.component";
import { LearnTreatmentComponent } from "./learn-treatment/learn-treatment.component";
import { LearnAdditionalResourcesComponent } from "./learn-additional-resources/learn-additional-resources.component";
var toastr = window['toastr'];
var jQuery = window['$'];
export var routes = [
    { path: '', component: HomeComponent },
    { path: 'learn', component: LearnComponent,
        children: [
            { path: '', redirectTo: '/fh-title-page', pathMatch: 'full' },
            { path: 'fh-title-page', component: LearnFhTitleComponent },
            { path: 'what-is-fh', component: LearnWhatIsFhComponent },
            { path: 'fh-considerations', component: LearnFhConsiderationsComponent },
            { path: 'fh-causes', component: LearnWhatCausesFhComponent },
            { path: 'fh-risks', component: LearnFhRisksComponent },
            { path: 'fh-symptoms', component: LearnFhSymptomsComponent },
            { path: 'genetics', component: LearnGeneticsComponent },
            { path: 'genetic-testing-faqs', component: LearnGeneticTestingFaqsComponent },
            { path: 'treatment', component: LearnTreatmentComponent },
            { path: 'additional-resources', component: LearnAdditionalResourcesComponent }
        ]
    },
    { path: 'share', component: ShareComponent },
    { path: 'tools', component: ToolsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'customerSupport', component: CustomerSupportComponent },
    { path: 'print', component: PrintComponent }
];
var ɵ0 = toastr, ɵ1 = jQuery;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
