/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/header.component.ngfactory";
import * as i2 from "../shared/header.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/router";
import * as i5 from "../shared/footer.component.ngfactory";
import * as i6 from "@angular/common";
import * as i7 from "../shared/footer.component";
import * as i8 from "../shared/common/mayo.service";
import * as i9 from "./layout.component";
var styles_LayoutComponent = [];
var RenderType_LayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
export function View_LayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "header-component", [["application", "FH Family Share"], ["secondary", "Familial Hypercholesterolemia"]], null, null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.HeaderComponent, [i3.Meta], { application: [0, "application"], secondary: [1, "secondary"] }, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "footer-component", [], null, null, null, i5.View_FooterComponent_0, i5.RenderType_FooterComponent)), i0.ɵprd(512, null, i6.DatePipe, i6.DatePipe, [i0.LOCALE_ID]), i0.ɵdid(6, 114688, null, 0, i7.FooterComponent, [i6.DatePipe, i8.MayoService], null, null)], function (_ck, _v) { var currVal_0 = "FH Family Share"; var currVal_1 = "Familial Hypercholesterolemia"; _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 3, 0); _ck(_v, 6, 0); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i0.ɵdid(1, 49152, null, 0, i9.LayoutComponent, [], null, null)], null, null); }
var LayoutComponentNgFactory = i0.ɵccf("app-root", i9.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
