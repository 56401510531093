
<div class="row w-100">
  <div class="col-lg-12">
    <div class="card fhDetailsRowStyle">
      <div class="card-body">
        <div class="label-title">FH Considerations for Children</div>
        <img class="fh-details-children-img col-lg-6 float-right" src="./dist/assets/images/learn-WhatIsFh1.jpg" alt="Table">
          <ul>
            <li class="fhDetails-li">FH is a genetic condition that runs in families - there is a 50% chance a parent with FH can pass an FH- causing variant to his or her child. To learn more about how FH is inherited <a  class="websiteLink" id="geneticLinkInFhConsiderations" routerLink="/learn/genetics">click here.</a></li>
            <li class="fhDetails-li">If a child has FH, he or she may have elevated LDL-cholesterol levels starting at birth, or even as a fetus.</li>
            <li class="fhDetails-li">FH is treatable in children and screening can start as early as 2 years of age. Screening includes a blood draw to measure lipid levels and genetic testing to check for any FH-causing variants. For young children, there is an option for blood to be collected using a ‘heel stick’ test which is less invasive.</li>
            <li class="fhDetails-li">The American Heart Association and the American Academy of Pediatrics support starting lipid lowering treatments, such as statins, in children to slow down and/or prevent heart disease later in life.</li>
            <li class="fhDetails-li">To determine the next best steps for your child, consider talking to your healthcare provider if you or your partner has FH.</li>
            <li class="fhDetails-li">If you are family planning and want to understand the risks associated with FH and how FH can be passed to your child, consider having a discussion with your healthcare provider or a genetic counselor. They may be able to share helpful resources with you.</li>
          </ul>
      </div>
    </div>
  </div>
</div> 

    