
   <div class="row w-100">
    <div class="col-lg-12">
      <div class="card fhDetailsRowStyle">
        <div class="card-body">
          <div class="label-title">How Common is FH?</div>
          <p  class="fhDetailsText">FH occurs in approximately 1 out of every 250 individuals. It is estimated that 1.3 million people have FH in the United States; however, only 10% of them have been diagnosed.</p>
          <div class="label-title">What is the Risk of Heart Attack with FH?</div>
          <p  class="fhDetailsText">FH is associated with greatly increased lifetime risk of premature cardiovascular disease including heart attack. <strong>FH patients have up to 20 fold increased risk of coronary heart disease.</strong></p>
          <p  class="fhDetailsText">If left untreated:</p>
          <ul>
            <li class="fhDetails-li">50% of men will have a coronary event such as a heart attack before age 50.</li>
            <li class="fhDetails-li">30% of women will have a coronary event such as a heart attack before age 60.</li>
          </ul>
          <p class="fhDetailsText">Risk of coronary heart disease can be reduced as much as 80% if FH is treated early in life with <a class="websiteLink" routerLink="/learn/treatment">lipid lowering therapies.</a></p>
          <img class="dna-img" src="./dist/assets/images/learn-heart-disease.jpg" alt="Coronary Artery Disease">
        </div>
      </div>
    </div>
  </div> 

    