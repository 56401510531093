﻿<main role="main" class="bg-light mainClass">
  <div class="container">
    <div class="row">
          <div class="col-md-6 col-lg-6 col-sm-12 card-deck">
            <div class="card cardStyleForImgPage">
              <img class="card-img-top" src="./dist/assets/images/tools1.jpg" alt="Card image cap">
              <div class="card-body">
                <h1 class="headingText">FH Conversation Aid</h1>
                <span class="tools-card-text">
                    <p class="card-text">Determine your risk of heart attack due to an FH diagnosis.</p>
                    <p class="card-text-note">Please click below to launch the conversation aid</p>
                </span>
                <a href="https://fhconversationaid.mayoclinic.org/" target="_blank" class="btn btn-primary buttonStyle">Open FH Conversation Aid</a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 card-deck">
            <div class="card cardStyleForImgPage">
              <img class="card-img-top"  src="./dist/assets/images/sh-discover-familytree-tool.jpg" alt="Card image cap">
              <div class="card-body">
                <h1 class="headingText">AboutMe Family Tree Tool</h1>
                <span class="tools-card-text">
                    <p class="card-text">Use the Mayo Clinic ‘AboutMe’ tool to build your family tree and keep track of family members that may be at risk of FH.</p>
                    <p class="card-text-note">Please create a Mayo Clinic account to use this tool</p>
                </span>
                <a modal-trigger="leaveSiteModal" class="btn btn-primary buttonStyle" id="familyTreeToolLink">Fill In Family Tree</a>
              </div>
            </div>
          </div>
    </div>
  </div>
</main>

<leavesite-modal closeOnBodyClick="true" elementId="leaveSiteModal" title="Launch About Me Family Tree Website" [url]="url" okButtonText="Go to Mayo Clinic About Me">
  <div>
      <p>You are now leaving the FHFamilyShare website and are going to the Mayo Clinic About Me Family Tree website.</p>
  </div>
</leavesite-modal> 

