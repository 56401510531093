/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./learn-fh-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./learn-fh-title.component";
var styles_LearnFhTitleComponent = [i0.styles];
var RenderType_LearnFhTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LearnFhTitleComponent, data: {} });
export { RenderType_LearnFhTitleComponent as RenderType_LearnFhTitleComponent };
export function View_LearnFhTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "main-label-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["What is FH?"])), (_l()(), i1.ɵeld(2, 0, null, null, 27, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "col-md-6 col-lg-6 col-sm-12 card-deck"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "card cardStyleForImgPage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "Card image cap"], ["class", "card-img-top"], ["src", "./dist/assets/images/learn-WhatIsFh1.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "card-text-top-img"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FH is a genetic condition that runs in families - there is a 50% chance that a parent with FH can pass an FH-causing variant to his or her child."])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "btn btn-primary buttonStyle"], ["id", "fhConsiderationsLinkInTitle"], ["routerLink", "/learn/fh-considerations"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Read More"])), (_l()(), i1.ɵeld(12, 0, null, null, 8, "div", [["class", "col-md-6 col-lg-6 col-sm-12 card-deck"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 7, "div", [["class", "card cardStyleForImgPage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["alt", "Card image cap"], ["class", "card-img-top"], ["src", "./dist/assets/images/learn-whatIsFh2.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "p", [["class", "card-text-top-img"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It is important to inform family members of their risk for FH; this will enable them to talk to their healthcare providers and get screened for FH."])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "a", [["class", "btn btn-primary buttonStyle"], ["id", "geneticsLinkInTitle"], ["routerLink", "/learn/genetics"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Read More"])), (_l()(), i1.ɵeld(21, 0, null, null, 8, "div", [["class", "col-md-6 col-lg-6 col-sm-12 card-deck"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 7, "div", [["class", "card cardStyleForImgPage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "img", [["alt", "Card image cap"], ["class", "card-img-top"], ["src", "./dist/assets/images/home3.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 5, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "p", [["class", "card-text-top-img"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Very often, individuals with FH have no symptoms at all until they experience a heart attack. This makes it very important to discuss screening for FH with a healthcare provider when there is a family history of early-onset."])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "a", [["class", "btn btn-primary buttonStyle"], ["id", "symptomsLinkInTitle"], ["routerLink", "/learn/fh-symptoms"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Read More"]))], function (_ck, _v) { var currVal_2 = "/learn/fh-considerations"; _ck(_v, 10, 0, currVal_2); var currVal_5 = "/learn/genetics"; _ck(_v, 19, 0, currVal_5); var currVal_8 = "/learn/fh-symptoms"; _ck(_v, 28, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 10).target; var currVal_1 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 19).target; var currVal_4 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 28).target; var currVal_7 = i1.ɵnov(_v, 28).href; _ck(_v, 27, 0, currVal_6, currVal_7); }); }
export function View_LearnFhTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-learn-fh-title", [], null, null, null, View_LearnFhTitleComponent_0, RenderType_LearnFhTitleComponent)), i1.ɵdid(1, 114688, null, 0, i4.LearnFhTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LearnFhTitleComponentNgFactory = i1.ɵccf("app-learn-fh-title", i4.LearnFhTitleComponent, View_LearnFhTitleComponent_Host_0, {}, { collapsedEvent: "collapsedEvent" }, []);
export { LearnFhTitleComponentNgFactory as LearnFhTitleComponentNgFactory };
