﻿<main role="main" class="bg-light mainClass">
    <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-6">
              <div class=" card thiscard card-body">
                <h5 class="card-title appointment-title">Request an Appointment</h5>
                <h6 class="card-subtitle mb-3 mt-3 ">The FH Clinic at Mayo Clinic Rochester, MN</h6>
                <p class="card-text">An appointment at the Mayo FH Clinic in Rochester, MN includes medical consultation with a physician expert in FH, review of medical therapy, including obtaining approval for a PCSK9 inhibitor, if indicated. Family screening and family counseling are also part of the visit. The Mayo FH clinic can provide ongoing support for FH patients.</p>
                <p class="card-subtitle">To Request an Appointment Contact</p>
                <div class="phoneNumberSection">
                  <div><b>Phone (FH Clinic)</b></div>
                  <div>(507) 284-4443</div>
                </div>
                <div class="phoneNumberSection">
                  <div><b>Phone (Central Appointment)</b></div>
                  <div>(507) 538-3270</div>
                </div>
                <div class="phoneNumberSection">
                  <div><b>Fax</b></div>
                  <div>(507) 266-5278</div>
                </div>
                <div class="phoneNumberSection">
                  <div><b>Address</b></div>
                  <div>Gonda-5, 200 First St SW, Rochester, MN 55905</div>
                </div>
                <p class="card-text">
                  To learn more about genetic testing and genetic counseling at Mayo Clinic, Rochester, MN<br/>
                  <a class="websiteLink" id="genomicsServicesLinkInContact" href="https://www.mayoclinic.org/departments-centers/clinical-genomics/services" target="_blank">Department of Clinical Genomics</a>
                </p>
                <p class="card-text"><b>
                  To find a genetic counselor near you
                  </b></p>
                  <a class="websiteLink" id="geneticCounselorLinkInContact" href="https://www.findageneticcounselor.com" target="_blank">www.findgeneticcounselor.com</a>
              </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class=" card thiscard">
              <div class="card-body">
                <h5 class="card-title">FH Clinic Contacts</h5>
                <ul class="list-group">
                  <li class="list-group-item this-item">
                    <div class="row">
                      <div class="col-md-2">
                        <img class="profile-pic" src="./dist/assets/images/contact-Kullo.jpg" alt="Card image cap">
                      </div>
                      <div class="col-md-8">
                        <h4 class="card-title profile-name">Iftikhar J. Kullo, M.D.</h4>
                            <p class="card-text profile-des">Cardiologist</p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item this-item">
                    <div class="row">
                      <div class="col-md-2">
                        <img class="profile-pic" src="./dist/assets/images/contact-Stephen.jpg" alt="Card image cap">
                      </div>
                      <div class="col-md-8">
                        <h4 class="card-title profile-name">Stephen L. Kopecky, M.D.</h4>
                            <p class="card-text profile-des">Cardiologist</p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item this-item">
                    <div class="row">
                      <div class="col-md-2">
                        <img class="profile-pic" src="./dist/assets/images/contact-Lopez.jpg" alt="Card image cap">
                      </div>
                      <div class="col-md-8">
                        <h4 class="card-title profile-name">Francisco Lopez-Jimenez, M.D.</h4>
                            <p class="card-text profile-des">Cardiologist</p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item this-item">
                    <div class="row">
                      <div class="col-md-2">
                        <img class="profile-pic" src="./dist/assets/images/contact-Alicia.jpg" alt="Card image cap">
                      </div>
                      <div class="col-md-8">
                        <h4 class="card-title profile-name">Alicia A. Mickow, APRN, C.N.P. M.S.N.</h4>
                            <p class="card-text profile-des">Nurse Practitioner</p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item this-item">
                    <div class="row">
                      <div class="col-md-2">
                        <img class="profile-pic" src="./dist/assets/images/contact-Erica.jpg" alt="Card image cap">
                      </div>
                      <div class="col-md-8">
                        <h4 class="card-title profile-name">Erica L. Kludtke, R.N.</h4>
                            <p class="card-text profile-des">Nurse</p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item this-item">
                    <div class="row">
                      <div class="col-md-2">
                        <img class="profile-pic" src="./dist/assets/images/contact-gc1-Cera.jpg" alt="Card image cap">
                      </div>
                      <div class="col-md-8">
                        <h4 class="card-title profile-name">Alanna M. Cera, M.S., CGC</h4>
                            <p class="card-text profile-des">Genetic Counselor</p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item this-item">
                    <div class="row">
                      <div class="col-md-2">
                        <img class="profile-pic" src="./dist/assets/images/contact-gc2-Lauren.jpg" alt="Card image cap">
                      </div>
                      <div class="col-md-8">
                        <h4 class="card-title profile-name">Lauren M. Boucher, M.S., CGC</h4>
                          <p class="card-text profile-des">Genetic Counselor</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class=" card thiscard">
              <div class="card-body profile-des" >
                <h5 class="card-title">Other Locations</h5>
                <div class="phoneNumberSection">
                  <div><b>Mayo Clinic in Arizona</b></div>
                  <div>800-466-2279</div>
                  <div>8 a.m. - 5 p.m. Mountain</div>
                </div>
                <div class="phoneNumberSection">
                  <div><b>Mayo Clinic in Florida</b></div>
                  <div>904-953-0853</div>
                  <div>7 a.m. - 6 p.m. Eastern</div>
                </div>
                <div class="phoneNumberSection">
                  <div><b>Mayo Clinic in Eau Claire, Wisconsin</b></div>
                  <div>715-838-6320</div>
                  <div>8 a.m. - 5 p.m. Central</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</main>