   <div class="main-label-title">What is FH?</div>
      <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-12 card-deck">
          <div class="card cardStyleForImgPage">
            <img class="card-img-top" src="./dist/assets/images/learn-WhatIsFh1.jpg" alt="Card image cap">
            <div class="card-body">
              <p class="card-text-top-img">FH is a genetic condition that runs in families - there is a 50% chance that a parent with FH can pass an FH-causing variant to his or her child.</p>
              <a class="btn btn-primary buttonStyle" id="fhConsiderationsLinkInTitle" routerLink="/learn/fh-considerations">Read More</a>
            </div>
          </div>
        </div>  
        <div class="col-md-6 col-lg-6 col-sm-12 card-deck">
          <div class="card cardStyleForImgPage">
            <img class="card-img-top" src="./dist/assets/images/learn-whatIsFh2.jpg" alt="Card image cap">
            <div class="card-body">
              <p class="card-text-top-img">It is important to inform family members of their risk for FH; this will enable them to talk to their healthcare providers and get screened for FH.</p>
              <a class="btn btn-primary buttonStyle" id="geneticsLinkInTitle" routerLink="/learn/genetics">Read More</a>
            </div>
          </div>
        </div>   
        <div class="col-md-6 col-lg-6 col-sm-12 card-deck">
          <div class="card cardStyleForImgPage">
            <img class="card-img-top" src="./dist/assets/images/home3.jpg" alt="Card image cap">
            <div class="card-body">
              <p class="card-text-top-img">Very often, individuals with FH have no symptoms at all until they experience a heart attack. This makes it very important to discuss screening for FH with a healthcare provider when there is a family history of early-onset.</p>
              <a class="btn btn-primary buttonStyle" id="symptomsLinkInTitle" routerLink="/learn/fh-symptoms">Read More</a>
            </div>
          </div>
        </div>
      </div>

    

    
    