 <div class="row w-100">
    <div class="col">
      <div class="card showTreatmentFaqsRowStyle">
        <div class="card-body">
          <div class="genetics-label-title">Treatment</div>
            <p class="treatmentText">FH is a genetic condition that is present from birth. Early diagnosis and treatment can help prevent adverse health outcomes associated with FH, such as heart attack. Both lifestyle changes and medications go together in helping individuals reach their cholesterol value goals. There is no cure for FH, however, treatment and lifestyle modifications can help individuals prevent heart attack and live normal, healthy lives. To learn more about these FH risks <a class="websiteLink" id="fhRisksLinkInTreatment" routerLink="/learn/fh-risks">click here.</a></p>
            <article>
              <div><h1>Cholesterol-Lowering Treatments for FH:</h1></div>
              <p class="treatmentText">Many people with FH are treated with a combination of the following cholesterol-lowering medications.</p>
              <ul>
                <li class="genetics-li">Statins, are considered the cornerstone of FH treatment and decrease the production of cholesterol in the body.</li>
                <li class="genetics-li">Ezetimibe inhibits the absorption of cholesterol from the gut.</li>
                <li class="genetics-li">Bile Acid Sequestrants bind bile, which is composed of cholesterol, and remove it from the body, thereby reducing LDL-cholesterol levels.</li>
                <li class="genetics-li">PCSK9 Inhibitors are antibodies that are given subcutaneously to <a class="websiteLink" id="fHCausesLinkInTreatment" routerLink="/learn/fh-causes">block the PCSK9 protein</a> in the body and prevent it from breaking down the LDL receptor. This allows more LDL-cholesterol to be removed from the blood because it can bind to the LDL receptor.</li>
                  <ul class="genetics-nested-li">
                    <li>PCSK9 inhibitors are a more expensive medication; insurance companies have different policies for approving these prescriptions.</li>
                    <li>Prior to getting approval by your insurance company for a PCSK9 inhibitor, you may have to try one or more less expensive lipid lowering medications such as statins and/or ezetimibe.</li>
                    <li>If you have genetically confirmed FH, that can help you get insurance approval for PCSK9 inhibitors. Consider talking to your healthcare provider if you’d like a genetic test for FH.</li>
                    <li>The Mayo FH Clinic has staff experienced with navigating insurance approvals and can help you advocate for a PCSK9 inhibitor medication. To make an appointment at the Mayo FH Clinic refer to the <a class="websiteLink" id="contactLinkInTreatment" routerLink="/contact">Contact</a> page.</li>
                  </ul>
                <li class="genetics-li">In rare instances, individuals may require more aggressive treatment in the form of LDL Apheresis, a procedure which filters bad cholesterol out from the blood. The procedure is considered safe in both adults and children and can be carried out on a weekly or biweekly basis, helping individuals maintain normal LDL-cholesterol levels in their body.</li>
                <li class="genetics-li">To learn more about these treatments and to help decide which may be best for you, please refer to the <a class="websiteLink" id="toolsLinkInTreatment" routerLink="/tools">FH Conversation Tool.</a></li>
              </ul>
            </article>
        </div>
      </div>         
    </div>
</div>   