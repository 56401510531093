
 <div class="row w-100">
  <div class="col">
    <div class="card showGeneticTestingFaqsRowStyle">
      <div class="label-title">Genetic Testing FAQ (Frequently Asked Questions)</div>
      <ul *ngIf="showFaq1" class="list-group">
        <li class="list-group-item label" >
          <a class="pls-icon" (click)="isFaq1Collapsed = !isFaq1Collapsed">
            <span *ngIf="!isFaq1Collapsed">+</span><span *ngIf="isFaq1Collapsed">-</span>
          </a>
          <span class="cursorStyle" (click)="isFaq1Collapsed = !isFaq1Collapsed">Who should consider genetic testing?</span>          
        </li>
        <div *ngIf="isFaq1Collapsed">
          <ul class="faqs-ul">
            <li class="faqs-li">The best way to confirm a diagnosis of <a  class="websiteLink" id="geneticsLinkInFaqs" routerLink="/learn/genetics">Autosomal Dominant Genetic</a> FH is to have genetic testing in a clinical genetics laboratory.</li>
            <li class="faqs-li">Genetic testing looks at a person’s genetic material, usually DNA, to find genetic variants (genetic differences) between that 	person’s DNA and the general population.</li>
            <li class="faqs-li">Every person is unique, and most genetic differences between people are not medically important.</li>
            <li class="faqs-li">People with FH often have genetic differences in the <a class="websiteLink" id="fhCausesLinkInFaqs" routerLink="/learn/fh-causes">LDLR, APOB and PCSK9 genes</a>, which cause 	LDL-cholesterol levels to build up in their blood.</li>
            <li class="faqs-li">This leads to a higher risk for heart attack, so it is important for health care providers to know if their patients have FH.</li>
            <li class="faqs-li">Because there is a 50% chance of passing on a pathogenic variant, genetic testing is important for family members 	including parents, siblings and children who may also be at risk for FH.</li>
          </ul>
        </div>
      </ul>
      <ul *ngIf="showFaq2" class="list-group">
        <li class="list-group-item label" >
          <a class="pls-icon" (click)="isFaq2Collapsed = !isFaq2Collapsed">
            <span *ngIf="!isFaq2Collapsed">+</span><span *ngIf="isFaq2Collapsed">-</span>
          </a>
          <span class="cursorStyle" (click)="isFaq2Collapsed = !isFaq2Collapsed">Who can I talk to about having genetic testing?</span>          
        </li>
        <div *ngIf="isFaq2Collapsed">
          <ul class="faqs-ul">
            <li class="faqs-li">If interested in genetic testing for FH, consider contacting a medical provider or speaking with a genetic counselor to discuss available options.</li>
            <li class="faqs-li">Genetic counselors are specialized medical professionals who can discuss genetic testing with you and review your family health history.</li>
            <li class="faqs-li">For individuals who can visit a Mayo Clinic location, a consultation with a genetic counselor can be arranged by contacting the Department of Clinical Genomics at Mayo Clinic in Rochester, MN at (507) 284-8198 or visiting the <a class="websiteLink" id="genomicsDeptLinkInFaqs" href='https://www.mayoclinic.org/departments-centers/clinical-genomics/services' target="_blank">Department of Clinical Genomics </a>webpage.</li>
            <li class="faqs-li">Those unable to visit a Mayo Clinic location can use a tool created by The National Society of Genetic Counselors (NSGC) to 	connect with a certified genetic counselor: <a class="websiteLink" id="geneticCounselorLinkInFaqs" href="https://www.findageneticcounselor.com" target="_blank">www.findageneticcounselor.com.</a></li>
          </ul>
        </div>
      </ul>
      <ul *ngIf="showFaq3" class="list-group">
        <li class="list-group-item label" >
          <a class="pls-icon" (click)="isFaq3Collapsed = !isFaq3Collapsed">
            <span *ngIf="!isFaq3Collapsed">+</span><span *ngIf="isFaq3Collapsed">-</span>
          </a>
          <span class="cursorStyle" (click)="isFaq3Collapsed = !isFaq3Collapsed">How does genetic testing work?</span>          
        </li>
        <div *ngIf="isFaq3Collapsed">
          <p>A person’s DNA can be taken from a blood sample, saliva sample, or sometimes from a tissue sample. A healthcare provider will often order a blood sample to have genetic testing preformed, as it is usually the most reliable source of DNA for testing. Saliva samples can be used to gather DNA as well, but in some instances may not provide enough DNA for testing and require additional collections.</p>
          <p>Tissue samples are sometimes used if:</p>
          <ul class="faqs-ul">
            <li class="faqs-li">A person has received a bone marrow transplant from a donor</li>
            <li class="faqs-li">A person has had a recent blood transfusion</li>
            <li class="faqs-li">A person has a cancer of the blood cells</li>
          </ul>
          <p>Samples provided for testing are often sent to a sequencing laboratory where testing will take place. These laboratories may ask for your permission to store or use your samples for research or development and may not be affiliated with your healthcare provider.</p>
        </div>
      </ul>
      <ul *ngIf="showFaq4" class="list-group">
        <li class="list-group-item label" >
          <a class="pls-icon" (click)="isFaq4Collapsed = !isFaq4Collapsed">
            <span *ngIf="!isFaq4Collapsed">+</span><span *ngIf="isFaq4Collapsed">-</span>
          </a>
          <span class="cursorStyle" (click)="isFaq4Collapsed = !isFaq4Collapsed">Is genetic testing expensive?</span>          
        </li>
        <div *ngIf="isFaq4Collapsed">
          <ul class="faqs-ul">
            <li class="faqs-li">In the recent past it was common for genetic testing to cost thousands of dollars; however much more affordable options have become available.</li>
            <li class="faqs-li">Insurance companies are increasingly willing to cover genetic testing costs as well, making genetic testing more available to patients with a suspected genetic disorder.</li>
            <li class="faqs-li">Some genetic testing companies offer a flat rate for genetic testing regardless of insurance coverage to make testing affordable for most people.</li>
          </ul>
        </div>
      </ul>
      <ul *ngIf="showFaq5" class="list-group">
        <li class="list-group-item label" >
          <a class="pls-icon" (click)="isFaq5Collapsed = !isFaq5Collapsed">
            <span *ngIf="!isFaq5Collapsed">+</span><span *ngIf="isFaq5Collapsed">-</span>
          </a>
          <span class="cursorStyle" (click)="isFaq5Collapsed = !isFaq5Collapsed">Can I order genetic testing through the internet?</span>          
        </li>
        <div *ngIf="isFaq5Collapsed">
          <p>While it is recommended that people visit with a healthcare provider or genetic counselor before having genetic testing for FH, it is not a requirement.</p>
          <p>In recent years, <b>direct-to-consumer (DTC) genetic testing</b> has become increasingly popular, and some DTC genetic testing companies have begun to offer testing for FH as part of their product-line.</p>
          <p>People considering these types of testing should be aware of the limitations of the FH test being ordered.</p>
          <ul class="faqs-ul">
            <li class="faqs-li">Some DTC companies could miss important genetic variants that can cause FH because they may use different techniques to analyze DNA. These tests may not always look for all the different types of gene changes that can cause FH.</li>
            <li class="faqs-li">Additionally, some clinical genetics laboratories have made their clinical FH tests available online. Clinical tests ordered online get reviewed by a physician before the test is ordered but can be completed from a person’s home using a saliva collection kit.</li>
          </ul>
          <p>It may be difficult to distinguish between a DTC testing company and a clinical genetics laboratory offering FH testing; therefore, it is recommended that individuals speak with a genetic counselor or medical provider for guidance about which testing options might be best for their situation.</p>
        </div>
      </ul>
      <ul *ngIf="showFaq6" class="list-group">
        <li class="list-group-item label" >
          <a class="pls-icon" (click)="isFaq6Collapsed = !isFaq6Collapsed">
            <span *ngIf="!isFaq6Collapsed">+</span><span *ngIf="isFaq6Collapsed">-</span>
          </a>
          <span class="cursorStyle" (click)="isFaq6Collapsed = !isFaq6Collapsed">What will my genetic test results look like?</span>          
        </li>
        <div *ngIf="isFaq6Collapsed">
          <ul class="faqs-ul">
            <li class="faqs-li">Genetic testing looks for genetic variants (genetic differences in a person’s DNA sample).</li>
            <li class="faqs-li">To assess whether or not a genetic variant is associated with disease, sequencing laboratories review many different features of 	genetic variants, such as how rare a genetic variant is, scientific or laboratory data which describes a genetic variant, computer-	based predictions of the consequences of a genetic variant, and other evidence.</li>
            <li class="faqs-li">If a laboratory classifies a variant as disease causing, it will be reported as ‘Pathogenic’ or ‘Likely Pathogenic’.</li>
            <li class="faqs-li">Occasionally, there is not enough evidence to classify a genetic variant, when this happens laboratories report the variant as a ‘Variant of Uncertain Significance’ (VUS).</li>
            <li class="faqs-li">Variants of Uncertain Significance (VUS) are not considered to be medically important, because there is not yet enough evidence to 	link them to disease. As scientific knowledge progresses, these variants may be reclassified.</li>
            <li class="faqs-li">If no disease-causing variant is found, laboratories will report this as ‘Negative’.</li>
          </ul>
        </div>
      </ul>
      <ul *ngIf="showFaq7" class="list-group">
        <li class="list-group-item label" >
          <a class="pls-icon" (click)="isFaq7Collapsed = !isFaq7Collapsed">
            <span *ngIf="!isFaq7Collapsed">+</span><span *ngIf="isFaq7Collapsed">-</span>
          </a>
          <span class="cursorStyle" (click)="isFaq7Collapsed = !isFaq7Collapsed">Can my genetic test results change?</span>          
        </li>
        <div *ngIf="isFaq7Collapsed">
          <ul class="faqs-ul">
            <li class="faqs-li">As additional knowledge emerges, it is anticipated that the interpretation of a genetic test result may change, however this does not mean that your genes have changed.</li>
            <li class="faqs-li">A reputable laboratory will generally ensure that updated results are issued if a variant is reclassified.</li>
          </ul>
        </div>
      </ul>
      <ul *ngIf="showFaq8" class="list-group">
        <li class="list-group-item label" >
          <a class="pls-icon" (click)="isFaq8Collapsed = !isFaq8Collapsed">
            <span *ngIf="!isFaq8Collapsed">+</span><span *ngIf="isFaq8Collapsed">-</span>
          </a>
          <span class="cursorStyle" (click)="isFaq8Collapsed = !isFaq8Collapsed">Is my genetic information safe?</span>          
        </li>
        <div *ngIf="isFaq8Collapsed">
          <ul class="faqs-ul">
            <li class="faqs-li">Genetic privacy and the use of genetic information by insurance companies or employers to make decisions have been 	controversial topics and may be an important consideration for genetic testing.</li>
            <li class="faqs-li">The Genetic Information Non-discrimination Act of 2008 (GINA) is a federal law which offers protections for individuals in the United 	States from certain types of genetic discrimination.</li>
            <li class="faqs-li">GINA prohibits health insurers and most employers from requesting or using genetic information in decision-making processes.</li>
            <li class="faqs-li">Notably, GINA does not apply to employers with less than 15 employees, and it does not provide protections for other types of insurance such as life, long-term care, or disability insurance.</li>
          </ul>
          <p>More detailed information about Genetic Information Non-discrimination Act can be viewed at the following websites:</p>   
          <ul>
            <li class="website-li"><a class="websiteLink" id="geneticDiscriminationLinkInFaqs" href="https://www.genome.gov/about-genomics/policy-issues/Genetic-Discrimination" target="_blank">Genetic Discrimination</a></li>
            <li class="website-li"><a class="websiteLink" id="ginaActLinkInFaqs" href="https://www.eeoc.gov/laws/statutes/gina.cfm" target="_blank">The Genetic Information Nondiscrimination Act of 2008</a></li>
            <li class="website-li"><a class="websiteLink" id="ginaHelpLinkInFaqs" href="http://www.ginahelp.org/GINAhelp.pdf" target="_blank">GINA Help</a></li>
          </ul> 
        </div>
      </ul>
      <ul *ngIf="showFaq9" class="list-group" id="lastItem">
        <li class="list-group-item label">
          <a class="pls-icon" (click)="isFaq9Collapsed = !isFaq9Collapsed">
            <span *ngIf="!isFaq9Collapsed">+</span><span *ngIf="isFaq9Collapsed">-</span>
          </a>
          <span class="cursorStyle" (click)="isFaq9Collapsed = !isFaq9Collapsed">Should my family members have genetic testing performed?</span>          
        </li>
        <div *ngIf="isFaq9Collapsed">
          <ul class="faqs-ul">
            <li class="faqs-li">If a pathogenic or likely pathogenic genetic variant is found for FH, biological relatives may also be at risk for having that genetic variant.</li>
            <li class="faqs-li"><b>First-degree relatives,</b> such as biological parents, siblings, or children have a 50% risk of having the same genetic variant.</li>
            <li class="faqs-li"><b>Second-degree relatives,</b> such as grandparents, aunts, or uncles would each have a 25% risk of having the same FH genetic variant.</li>
            <li class="faqs-li"><b>It is recommended that all first-degree relatives have genetic testing for FH if a pathogenic or likely pathogenic variant is found in a family member.</b></li>
            <li class="faqs-li">The figure below shows the risk of FH in relatives when one family member has been found to have a pathogenic or likely pathogenic FH variant.</li>
          </ul>
          <img class="faq-img-9" src="./dist/assets/images/learn-faq-family-testing.jpg" alt="FAQ">
        </div>
      </ul>
    </div>
  </div>
 </div>