
<div id="{{elementId}}" #modalcontainer class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{title}}</h5>
                <button type="button" class="cancel" data-dismiss="modal" aria-label="Cancel">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-content></ng-content>
            </div>
            <div class="modal-footer footerStyle">
                <div class="row txt-center">
                    <div class="col-md-4 col-sm-12 col-lg-4">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                    <div class="col-md-8 col-sm-12 col-lg-8">
                        <button type="button" (click)="launchUrl();" class="btn btn-success" data-dismiss="modal">{{okButtonText}}</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
  