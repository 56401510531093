<div class="row w-100 h-100">
  <div class="col">
    <div class="card">
      <div class="card-body detailsCard additionalResourcesRowStyle">
        <div class="label-title">Links</div>
        <ul>
          <li class="resources-li"><a href="https://www.mayoclinic.org/" class="printable-link" id="mayoClinicOrgResource" target="_blank">MayoClinic.org </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://www.mayo.edu/research/clinical-trials/cls-20271796" class="printable-link"  id="registryStudyResource" target="_blank">CASCADE FH Registry Study </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://www.mayoclinic.org/departments-centers/clinical-genomics/overview" class="printable-link" id="genomicsDeptResource" target="_blank">Mayo Clinic Department of Clinical Genomics </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://www.mayo.edu/research/clinical-trials" class="printable-link" id="clinicalTrailsResource" target="_blank">Mayo Clinic Clinical Trials</a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://www.mayo.edu/research/labs/atherosclerosis-lipid-genomics/overview" class="printable-link" id="genomicsLabResource" target="_blank">Mayo Clinic Atherosclerosis & Lipid Genomics Laboratory </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://www.mayo.edu/research/clinical-trials/diseases-conditions/familial-hypercholesterolemia/" class="printable-link" id="researchResource" target="_blank">Mayo Clinic Familial Hypercholesterolemia Research </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://www.mayoclinic.org/diseases-conditions/familial-hypercholesterolemia/symptoms-causes/syc-20353755" class="printable-link" id="healthInfoResource" target="_blank">Mayo Clinic Familial Hypercholesterolemia - Patient Care & Health Information </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://pubmed.ncbi.nlm.nih.gov/?term=familial+hypercholesterolemia" class="printable-link" id="publicationsResource" target="_blank">PubMed Publications </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://thefhfoundation.org/" class="printable-link" id="heartFoundationResource" target="_blank">Family Heart Foundation Website </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://www.heart.org/" class="printable-link" id="ahaResource" target="_blank">American Heart Association (AHA) </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://www.lipid.org/" class="printable-link" id="nlaResource" target="_blank">National Lipid Association (NLA) </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://findageneticcounselor.nsgc.org/" class="printable-link" id="counselorResource" target="_blank">Find a Genetic Counselor </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="https://www.mayoclinic.org/patient-education?mc=MC8455-01" class="printable-link" id="highCholestrolpatientEducationResource" target="_blank">A Message for People with Stubbornly High Cholesterol, MC8455-01 </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
          <li class="resources-li"><a href="./dist/assets/files/mc8455.pdf"  class="printable-link" id="patientEducationResource" target="_blank">Mayo Clinic Patient Education Familial Hypercholesterolemia </a>
            <svg title="ExternalLink" class="external-link-icon">
              <use xlink:href="#external-link-alt" />
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>