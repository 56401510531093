
  <div class="row w-100">
    <div class="col-lg-12">
      <div class="card fhDetailsRowStyle">
        <div class="card-body">
          <div class="label-title">What Are Symptoms of FH?</div>
          <p class="fhDetailsText">Very often, individuals with FH have no symptoms at all until they experience a heart attack. This makes it very important to discuss screening for FH with a healthcare provider when there is a family history of <a class="websiteLink" id="fhLinkInSymptoms" routerLink="/learn/what-is-fh">early-onset coronary heart disease.</a></p>
          <p class="fhDetailsText">When symptoms for FH do occur, they can include:</p>
          <ul>
            <li class="fhDetails-li">Cholesterol deposits in a ring around the cornea of the eye. This is called  ‘Corneal Arcus’. (See Figure 1)</li>
            <li class="fhDetails-li">Cholesterol deposits on the skin, particularly around tendons of the hands and feet. This is called ‘Xanthoma’ (zan-ˈthō-mə). (See Figure 2 & 3)</li>
            <li class="fhDetails-li">Chest pain can occur on exertion and is due to cholesterol deposits in the arteries which make them narrow and decrease the blood flow to the heart. This is called Angina. (See Figure 4)</li>
            <li class="fhDetails-li">Chest pain can also occur at rest or with minimal exertion. This is called Unstable Angina and requires immediate medical attention due to increased risk of heart attack. (See Figure 4)</li>           
          </ul>
          <img class="fh-details-tbl-img" src="./dist/assets/images/learn-symptoms.jpg" alt="Symptoms">
        </div>
      </div>
    </div>
  </div>