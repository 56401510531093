<div class="row w-100">
  <div class="col-lg-12">
    <div class="card">
      <div class="genetics-label-title">Genetics</div>
      <div><h1>Inheritance:</h1></div>
      <ul>
        <li class="genetics-li">Familial Hypercholesterolemia (FH) is a genetic disorder in which the process of moving LDL or “bad” cholesterol out of the bloodstream is affected.</li>
        <li class="genetics-li">FH is most commonly caused by pathogenic variants in three genes called <span class="geneText">LDLR, </span><span class="geneText">APOB</span> and <span class="geneText">PCSK9. </span>To learn more about what causes FH<a class="websiteLink" id="fhCausesLinkInGenetics" routerLink="/learn/fh-causes"> click here.</a></li>
        <li class="genetics-li">These genes can be thought of as instructions to make molecules in the body, which help the body process LDL-cholesterol.</li>
        <li class="genetics-li">A typical person has two copies of each FH gene — one copy is passed down from their mother, and one copy is passed down by their father.</li>
        <li class="genetics-li">FH is an autosomal dominant genetic disorder, meaning it only takes one malfunctioning gene copy to cause FH, and men and women have the same chance of inheriting a copy of the gene.</li>
        <li class="genetics-li">If a parent has FH, each of their biological children has a 50% chance of inheriting a malfunctioning FH gene from that parent (see Figure below).</li>
        <li class="genetics-li">If a person has one malfunctioning copy of an FH gene and one healthy copy of an FH gene, they have heterozygous FH. Heterozygous FH is the most common form of FH.</li>
        <li class="genetics-li">Rarely, a person may inherit two malfunctioning copies of FH genes. This leads to a severe form of FH known as homozygous FH, which can be life-threatening from a young age.</li>
      </ul>
      <img class="gentics-img" src="./dist/assets/images/genetics_fh.jpg" alt="Genetics">
    </div>
  </div>
</div> 

    