﻿<main role="main" class="bg-light mainClass">
  <div class="container pt-3">
    <div class="row d-print-none">
      <div [ngClass]="{'activeTab' : isFamilyLetterSelected}" class="col-sm-6 letterSection" id="showFamilyLetterLink" (click)="showFamilyLetter()">
        <p  class="shareLetterHeadingText">Share Family Letter</p> 
      </div>
      <div [ngClass]="{'activeTab' : isHealthCareProviderLetterSelected}" class="col-sm-6 letterSection" id="showHealthCareProviderLetterLink"  (click)="showHealthCareProviderLetter()">
        <p class="shareLetterHeadingText">Share Health Care Provider Letter</p> 
      </div>
    </div>
    <div class="row bg-white familyShareRow d-print-none">
      <div *ngIf="isFamilyLetterSelected" class="col-sm-6 col-xs-12" >
       <p class="shareLetterHeadingText">Family Share Letter</p>
      </div>
      <div *ngIf="isHealthCareProviderLetterSelected" class="col-sm-6 col-xs-12" >
        <p class="shareLetterHeadingText d-print-none">Healthcare Provider Share Letter</p>
      </div>
      <div *ngIf="isFamilyLetterSelected || isHealthCareProviderLetterSelected" class="col-sm-6 col-xs-12">
        <div class="col-xs-12">
          <a class="btn mcui-btn primary btn-lg round buttonStyle" id="copyAndShareId" href={{letterText}} (click)="onCopyAndShareLetterButtonClick()">
            <span class="align-middle pl-1 buttonText">Copy And Share</span>
          </a>
        </div>
        <div class="col-xs-12">
          <a class="btn mcui-btn primary btn-lg round buttonStyle" id="printLetterId" (click)="printText()">
            <span class="align-middle pl-1 buttonText">Print Letter</span>
          </a>
        </div>       
      </div>
    </div>   
    <div *ngIf="isFamilyLetterSelected" class="row bg-white letterArticleRow">
      <div class="col md-12">
      <article class="letterArcticle">
        <section class="sectionText" id="printableText">
            <p>Dear Family Member,</p>
            <p>I have been diagnosed with Familial Hypercholesterolemia (FH), a genetic disorder that leads to high cholesterol levels in the blood if left untreated. FH can increase the risk for premature heart disease (including heart attack) by up to 20-fold.</p>
            <p>Because this condition runs in families, you may also be at risk for FH. The good news is that heart disease due to FH can be prevented by medications that lower cholesterol levels.</p>
            <p>My genetic test identified a disease-causing variant <input type="text" class="inputTextBox" id="variantStyle" *ngIf="!togglePrint" [(ngModel)]="variantNameFam" [ngStyle]="getVariantStyle()" placeholder="Variant Name" minlength="5" maxlength="20"/>
              <span class="placeholderText" *ngIf="togglePrint && variantNameFam.length==0">Variant Name</span>
              <span *ngIf="togglePrint && variantNameFam.length>0" [ngStyle]="getVariantStyle()">{{variantNameFam}}</span> in the <input type="text" class="inputTextBox" *ngIf="!togglePrint" [(ngModel)]="geneNameFam" [ngStyle]="getGeneStyle()" placeholder="Gene Name" minlength="5" maxlength="20"/>
              <span class="placeholderText" *ngIf="togglePrint && geneNameFam.length==0">Gene Name</span>
              <span *ngIf="togglePrint && geneNameFam.length>0" [ngStyle]="getGeneStyle()">{{geneNameFam}}</span> gene. The test was performed at <input type="text" class="inputTextBox" *ngIf="!togglePrint" [(ngModel)]="labNameFam" [ngStyle]="getLabStyle()" placeholder="Genetic Testing Lab" minlength="5" maxlength="20"/>
              <span class="placeholderText" *ngIf="togglePrint && labNameFam.length==0">Genetic Testing Lab</span>
              <span *ngIf="togglePrint && labNameFam.length>0" [ngStyle]="getLabStyle()">{{labNameFam}}</span>, with the following accession code <input type="text" class="inputTextBox" *ngIf="!togglePrint" [(ngModel)]="accessionCodeFam" [ngStyle]="getAccessionCodeStyle()" placeholder="Accession Code"  minlength="5" maxlength="20"/>
              <span class="placeholderText" *ngIf="togglePrint && accessionCodeFam.length==0">Accession Code</span>
              <span *ngIf="togglePrint && accessionCodeFam.length>0" [ngStyle]="getAccessionCodeStyle()">{{accessionCodeFam}}</span>. Your healthcare provider can order a genetic test to determine whether you carry the same variant that leads to FH. </p>
            <p>It might be helpful for you to meet with a healthcare provider to discuss this further. The FH Clinic at Mayo Clinic in Rochester, MN, can be reached at (507) 284-4443 for an appointment. The <a [routerLink]="['/']" class="websiteLink" id="fhHomeInShare">fhfamilyshare.mayoclinic.org</a> website, developed by Mayo Clinic, provides useful information about FH, and includes contact information for Mayo Clinic locations in Florida, Arizona, and the Mayo Clinic Health System.</p>
            <p>You can learn more about genetic testing or schedule an appointment with a genetic counselor at Mayo Clinic by visiting the <a class="websiteLink" id="genomicsServicesLinkInShare" href='https://www.mayoclinic.org/departments-centers/clinical-genomics/services' target="_blank">www.mayoclinic.org/departments-centers/clinical-genomics/services</a> or <a class="websiteLink" id="geneticCounselorLinkInShare" href="https://www.findageneticcounselor.com" target="_blank">www.findageneticcounselor.com</a> for a list of genetic counselors in your area.</p>
            <p>I hope this information is helpful.</p>
            <p>Thank You.</p>  
            <input type="text" class="inputTextBox" [(ngModel)]="fromNameFam" [ngStyle]="getFromNameStyle()" placeholder="Name" maxlength="20"/> 
        </section>
      </article>
    </div>
    </div>
    <div *ngIf="isFamilyLetterSelected" class="row bg-white letterTipsRow d-print-none">
      <div class="col md-12">
      <article class="letterTipsArticle">
        <section>
            <h1 class="shareLetterHeadingText">Tips for Sharing with Family Members About Your FH Diagnosis</h1>
            <ul class="sectionText">
              <li>Think about how you are going to have this conversation. Do you and your family members talk most often by phone? Is there an upcoming family reunion? Is social media the preferred method of communication? There is no wrong way to pass along these results, but it is important to think about the best method for your family.</li>
              <li>Identify the “communicator” in the family. Who is the person that disperses information to you or other family members? Recruit that individual to help you spread the information about your diagnosis and the potential risk for your relatives.</li>
              <li> Print out or email the family letter available in the ‘Share’ section of this website, to convey implications of your FH diagnosis to relatives. This document includes information about your genetic testing, diagnosis and how other family members can be tested and/or learn more.</li>
              <li>Pass along resources; bring brochures, booklets, and other educational materials for your relatives.</li>
              <li>Know that kids are not immune! High cholesterol can affect young children and their provider may recommend treatment starting at age 8-10 years. It is important for your relatives to know that this is not just an adult-onset condition.</li>
              <li>Respect your family members. Others may feel differently about having or being at risk for FH. Know that if you communicate this information to your relatives, you have done your part and how people will act on this information is an individual decision.</li> 
            </ul>
        </section>
      </article>
      </div>
    </div>
    <div *ngIf="isHealthCareProviderLetterSelected"  class="row bg-white letterArticleRow" >
      <div class="col md-12">
      <article class="letterArcticle">
        <section class="sectionText" id="printableText">
          <p>Dear Healthcare Provider,</p>
          <p>I have been found to have an inherited condition called Familial Hypercholesterolemia (FH). I had genetic testing done which identified a pathogenic mutation in the <input type="text" class="inputTextBox" *ngIf="!togglePrint" [(ngModel)]="geneNameProv" [ngStyle]="getGeneStyle()" placeholder="Gene Name" minlength="5" maxlength="20"/>
            <span class="placeholderText" *ngIf="togglePrint && geneNameProv.length==0">Gene Name</span>
            <span *ngIf="togglePrint && geneNameProv.length>0" [ngStyle]="getGeneStyle()">{{geneNameProv}}</span> gene called <input type="text" class="inputTextBox" id="variantStyle" *ngIf="!togglePrint" [(ngModel)]="variantNameProv" [ngStyle]="getVariantStyle()" placeholder="Variant Name" minlength="5" maxlength="20"/>
            <span class="placeholderText" *ngIf="togglePrint && variantNameProv.length==0">Variant Name</span>
            <span *ngIf="togglePrint && variantNameProv.length>0" [ngStyle]="getVariantStyle()">{{variantNameProv}}</span>.This testing was performed at the <input type="text" class="inputTextBox" *ngIf="!togglePrint" [(ngModel)]="labNameProv" [ngStyle]="getLabStyle()" placeholder="Genetic Testing Lab" minlength="5" maxlength="20"/>
            <span class="placeholderText" *ngIf="togglePrint && labNameProv.length==0">Genetic Testing Lab</span>
            <span *ngIf="togglePrint && labNameProv.length>0" [ngStyle]="getLabStyle()">{{labNameProv}}</span> with the following <input type="text" class="inputTextBox" *ngIf="!togglePrint" [(ngModel)]="accessionCodeProv" [ngStyle]="getAccessionCodeStyle()" placeholder="Accession Code"  minlength="5" maxlength="20"/>
            <span class="placeholderText" *ngIf="togglePrint && accessionCodeProv.length==0">Accession Code</span>
            <span *ngIf="togglePrint && accessionCodeProv.length>0" [ngStyle]="getAccessionCodeStyle()">{{accessionCodeProv}}</span> accession code.</p>
          <p>FH is a genetic disorder that leads to high cholesterol levels in the blood and up to a 20-fold increased risk for premature heart disease (including heart attack), if left untreated. One can have FH without any physical signs or symptoms. FH needs to be treated through medication and lifestyle changes. I would like to discuss my diagnosis of FH further with you.</p>
          <p>Thank You.</p>
          <input type="text" [(ngModel)]="fromNameProv"  class="inputTextBox" [ngStyle]="getFromNameStyle()" placeholder="Name" maxlength="20"/>
        </section>
      </article>  
      </div> 
    </div>
    <div *ngIf="isHealthCareProviderLetterSelected" class="row bg-white letterTipsRow d-print-none">
      <div class="col md-12">
      <article class="letterTipsArticle">
        <section>
          <h1 class="shareLetterHeadingText">Tips for Communicating with a Healthcare Provider About Your FH Diagnosis</h1>
          <ul  class="sectionText">
            <li>Give resources to your provider so they can be aware of FH.</li>
            <li>Share your experience. Make sure your provider understands your journey with high cholesterol and your diagnostic process.</li>
            <li>Be organized—know the medications you are on and the doses.</li>
            <li>Be aware of your family history to share with your provider.</li>
            <li>Be sure to advocate for yourself.</li>
          </ul>
        </section>
      </article>
      </div>
    </div>
  </div>
</main>
