﻿<footer id="footer" class="bg-dark footer division py-3 footerStyle">
    <div id="pre-footer" class="p-top-30 p-bottom-30">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12  col-xl-5 pr-0">
                    <div class="text-left mx-auto px-1">
                        &copy; 1998-{{year}} Mayo Foundation for Medical Education and Research (MFMER). All rights reserved.
                    </div>
                </div>
                <div class="col-12  col-xl-5 d-print-none pr-0">
                    <div class="text-left mx-0">
                        <ul class="list-inline">
                            <li class="list-inline-item border-right pr-2"><a href="https://mayoclinic.org/" id="mayoOrgInFooter" target="_blank">MayoClinic.org</a></li>
                            <li class="list-inline-item border-right pr-2"><a href="http://www.mayoclinic.org/about-this-site/privacy-policy.html" id="privacyPolicyLink" target="_blank">Privacy Policy</a></li>
                            <li class="list-inline-item border-right pr-2"><a href="#/" id="privacyManageCookies" class="manageCookies" id="manageCookies">Manage Cookies</a></li>
                            <li class="list-inline-item"><a href="http://www.mayoclinic.org/about-this-site/terms-conditions-use-policy.html" id="termsAndConditions" target="_blank">Terms and Conditions</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12  col-xl-2 d-print-none pr-0">
                    <div class="text-left mx-auto pt-2 px-1">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a href="https://www.facebook.com/MayoClinic" id="mayoFbLink" target="_blank">
                                    <svg title="Facebook" class="social-icons">
                                        <use xlink:href="#facebook-square"/>
                                    </svg>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://www.pinterest.com/mayoclinic/" id="mayoPinterest" target="_blank">
                                    <svg title="Pinterest" class="social-icons">
                                        <use xlink:href="#pinterest-square"/>
                                    </svg>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://twitter.com/MayoClinic_KLab" id="mayoKlab" target="_blank">
                                    <svg title="Twitter" class="social-icons">
                                        <use xlink:href="#twitter-square"/>
                                    </svg>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://www.youtube.com/user/mayoclinic" id="mayoSocialIcons" target="_blank">
                                    <svg title="Youtube" class="social-icons">
                                        <use xlink:href="#youtube-square"/>
                                    </svg>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://www.instagram.com/mayoclinic/?hl=en" id="mayoInstagram" target="_blank">
                                    <svg title="Instagram" class="social-icons">
                                        <use xlink:href="#instagram"/>
                                    </svg>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#" class="back-to-top d-print-none">
                                    <i class="fa fa-caret-up"></i>
                                </a>
                            </li>
                        </ul>
                    </div>                   
                </div>
            </div> 
        </div>	  
    </div>	  
</footer>

