﻿<!DOCTYPE html> 
<html> 
  <head> 
    <meta http-equiv="X-UA-Compatible" content="IE=edge"> 
  </head>
<main role="main" class="bg-light mainClass"> 
   <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of slidesStore">
      <ng-template carouselSlide [id]="slide.id">
        <img class="carousel-images-style" [src]="slide.src">
        <h3 class="title home-text">{{slide.title}}</h3>
      </ng-template>
    </ng-container>
    </owl-carousel-o>
    <div class="container container-style">  
        <div class="row">
            <div class="col">
                <div class="card home-card">
                    <div class="card-body">
                        <p class="card-text">FH is a genetic condition that runs in families and can cause high levels of “bad” cholesterol, also known as LDL-cholesterol, at an early age. Click on each topic to learn more about FH and how to manage it.</p>
                            <a [routerLink]="['/learn/fh-title-page']" class="btn mcui-btn primary btn-lg round pull-right buttonStyle" id="fhTitlePageCard1">
                            <span class="align-middle pl-1 buttonText">Learn About FH</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card home-card">
                    <div class="card-body">
                        <p class="card-text">It is important to let family members know about FH. Use the Share feature on this website to email letters to your family members and get the word out about FH.</p>
                        <a [routerLink]="['/share']"  class="btn mcui-btn primary btn-lg round pull-right buttonStyle" id="fhTitlePageCard2">
                            <span class="align-middle pl-1 buttonText">Share With Family</span>
                        </a>
                    </div>
                 </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card home-card">
                    <div class="card-body">
                        <p class="card-text">FH increases a person’s risk of early-onset heart disease. Use the Family Tree tool to discover which of your relatives may be at risk for FH. Use the FH Conversation Tool to determine your risk of having a heart attack.</p>
                        <a [routerLink]="['/tools']"  class="btn mcui-btn primary btn-lg round pull-right buttonStyle" id="fhTitlePageCard3">
                            <span class="align-middle pl-1 buttonText">Explore Tools</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</html>