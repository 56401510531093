
    <div class="card cardStyle">
      <ul class="list-group">
        <li class="list-group-item label" id="fhFirstId" [routerLink]="['fh-title-page']" routerLinkActive="active">
          <a class="pls-icon" (click)="isCollapsed = !isCollapsed" >
            <span *ngIf="!isCollapsed">+</span><span *ngIf="isCollapsed">-</span>
          </a>
          <span (click)="isCollapsed = !isCollapsed">Overview of FH</span>          
        </li>
        <div id="collapseExample" *ngIf="isCollapsed" >
          <ul class="fh-ul">
            <li class="fh-li" routerLinkActive="activeListItem" id="whatIsFH" [routerLink]="['what-is-fh']">What is FH?</li> 
            <li class="fh-li" routerLinkActive="activeListItem" id="fHConsiderations" [routerLink]="['fh-considerations']">FH Considerations for Children</li>
            <li class="fh-li" routerLinkActive="activeListItem" id="fHCauses" [routerLink]="['fh-causes']">What Causes FH?</li>
            <li class="fh-li" routerLinkActive="activeListItem" id="fHRisks" [routerLink]="['fh-risks']">Prevalence & Risks of FH</li>
            <li class="fh-li" routerLinkActive="activeListItem" id="fHSymptoms" [routerLink]="['fh-symptoms']">What are Symptoms of FH?</li>
        </ul>
        </div>
        <li class="list-group-item label" id="genetics" [routerLink]="['genetics']" routerLinkActive="active">Genetics</li>
        <li class="list-group-item label" id="geneticTestingFaqs" [routerLink]="['genetic-testing-faqs']" routerLinkActive="active">Genetic Testing FAQs</li>
        <li class="list-group-item label" id="treatment" [routerLink]="['treatment']" routerLinkActive="active">Treatment</li>
        <li class="list-group-item label" id="additionalResources" [routerLink]="['additional-resources']" routerLinkActive="active">Additional Resources</li>
      </ul>
    </div>