﻿<header class="header">
    <div class="container">
    <nav class="navbar header-top navbar-expand-xl mayo justify-content-center d-print-flex">
        <a class="navbar-brand d-print-inline-block " id="headerLogo" [routerLink]="['/']">
            <img alt="Mayo Clinic" src="./dist/assets/core/logo/mayo-stack_black.svg"/>
            <span class="application-title d-print-inline-block align-middle">{{application}}</span>
        </a>
        <button class="navbar-toggler d-print-none" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText"
                aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse flex-column d-print-none" id="navbarText">
            <div class="row">
                <div class="col header-col" >
                    <div class="navbar-nav mb-lg-0 ml-md-auto mt-3">
                        <a class="navbar-brand application-title" id="primaryTitleInHeader" ng-reflect-router-link="/" href="/"><span>{{application}}</span></a>
                    </div>
                    <div class="navbar-nav mb-lg-0 ml-md-auto display-screen">
                        <a class="navbar-brand application-secondary-title" id="secondaryTitleInHeader" ng-reflect-router-link="/" href="/"><span>{{secondary}}</span></a>
                    </div>
                </div>
                <div class="col header-col">
                    <button type="button" class="btn mcui-btn primary btn-lg round text-center" id="requestAnAppointment" title="Request An Appointment" (click)="appointmentClicked()">
                        <span id="appointmentText">Request An Appointment</span>
                    </button>
                </div>
            </div>
            <ul id="main-navigation" class="navbar-nav  ml-md-auto d-md-flex  d-print-none">
                <li class="nav-item">
                    <a class="nav-link" id="homeInHeader" [routerLink]="['/']" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active_route"><span class="nav-item-heading">HOME</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="learnInHeader" [routerLink]="['learn/fh-title-page']" routerLinkActive="active_route"><span class="nav-item-heading">LEARN</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="shareInHeader" [routerLink]="['share']" routerLinkActive="active_route"><span class="nav-item-heading">SHARE</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="toolsInHeader" [routerLink]="['tools']" routerLinkActive="active_route"><span class="nav-item-heading">TOOLS</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="contactInHeader" [routerLink]="['contact']" routerLinkActive="active_route"><span class="nav-item-heading">CONTACT</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="customerSupportInHeader" [routerLink]="['customerSupport']" routerLinkActive="active_route"><span class="text-nowrap nav-item-heading">CUSTOMER SUPPORT</span></a>
                </li>
            </ul>
        </div>
    </nav>
    </div>
</header>	