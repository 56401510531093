<main role="main">
<div class="nonav large">
  <div class="content">
    <div class="row">
      <div class="form">
        <article class="supportText">
          <p id='top'><strong class="warningText">IF THIS IS A MEDICAL OR MENTAL HEALTH EMERGENCY, CALL 911 OR YOUR LOCAL EMERGENCY SERVICE.</strong></p>
          <h1>Need Additional Help?</h1>
          <p>It may be something simple!</p>
          <p>Check the topics below to see if the troubleshooting steps help resolve your issue(s):</p>
          <ul class="faq-questions">
            <li><span class="clickableText"><a id="supportFaq1" (click)="onClickScroll('q1')">Unable to open the Share letter in default browser</a></span></li>
            <li><span class="clickableText"><a id="supportFaq2" (click)="onClickScroll('q2')">Unable to correctly copy the Share letter text into the default email client of the user</a></span></li>
            <li><span class="clickableText"><a id="supportFaq3" (click)="onClickScroll('q3')">Unable to email the Share letter</a></span></li>
            <li><span class="clickableText"><a id="supportFaq4" (click)="onClickScroll('q4')">Unable to print Share letter</a></span></li>
            <li><span class="clickableText"><a id="supportFaq5" (click)="onClickScroll('q5')">Pictures not loading and/or expandable pictures not working correctly</a></span></li>
            <li><span class="clickableText"><a id="supportFaq6" (click)="onClickScroll('q6')">Problem with accessing website from other devices e.g., cell phone or tablet</a></span></li>
          </ul>  
          <p id='q1'><strong>Unable to open the Share letter in default browser</strong></p>
          <p>Try the following troubleshooting steps:</p>
          <ul>
            <li>Review the Supported Web Browser list:
              <ul>
                <li>Windows
                  <ul>
                    <li>Chrome 8.0</li>
                    <li>Firefox 10</li>
                    <li>Edge 80</li>
                  </ul>
                </li>
                <li>MAC
                  <ul>
                    <li>Chrome 8.0</li>
                    <li>Firefox 10</li>
                    <li>Safari 12</li>
                  </ul>
                </li>
                <li>iPhone/iPad
                  <ul>
                    <li>Chrome 8.0</li>
                    <li>Safari 12</li>
                  </ul>
                </li>
                <li>Android
                  <ul>
                    <li>Chrome 8.0</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>Restart your browser and test the website.</li>
            <li>Clear your browser’s cookies and cache, then restart your browser and test the website.</li>
            <li>Restart your device and test the website.</li>
            <li>Try an alternate browser to see if the issue persists.</li>
          </ul>
        <p><span class="clickableText"><a id="backToTopQ1" (click)="onClickScroll('top')">Back to Top</a></span></p>
        <p id='q2'><strong>Unable to correctly copy the Share letter text into the default email client of the user</strong></p>
        <p>Try the following troubleshooting steps:</p>
        <ul>
          <li>Restart your browser and try copying the text again.</li>
          <li>Clear the device’s clipboard cache.</li>
          <li>Try using the device’s keyboard hotkeys by copying the text with CTRL + C, then using CTRL + V for pasting the text.</li>
          <li>Restart your email client.</li>
          <li>Restart your device.</li>
        </ul>
        <p><span class="clickableText"><a id="backToTopQ2" (click)="onClickScroll('top')">Back to Top</a></span></p>
        <p id='q3'><strong>Unable to email the Share letter</strong></p>
        <p>Try the following troubleshooting steps:</p>
        <ul>
          <li>Verify that your device has a network connection.</li>
          <li>Try an alternate email client to see if you get the same results.</li>
          <li>Restart your device.</li>
        </ul>
        <p><span class="clickableText"><a id="backToTopQ3" (click)="onClickScroll('top')">Back to Top</a></span></p>
        <p id='q4'><strong>Unable to print Share letter</strong></p>
        <p>Try the following troubleshooting steps:</p>
        <ul>
          <li>Restart your printer.</li>
          <li>Restart your PC device.</li>
          <li>Check your printer to see if there are any error messages\warning lights.</li>
          <li>Clear the printer queue to see if any print jobs are hung and need to be deleted.</li>
          <li>Check to see if the printer doesn’t have paper or if there is a paper jam.</li>
        </ul>
        <p><span class="clickableText"><a id="backToTopQ4" (click)="onClickScroll('top')">Back to Top</a></span></p>
        <p id='q5'><strong>Pictures not loading and/or expandable pictures not working correctly</strong></p>
        <p>Try the following troubleshooting steps:</p>
        <ul>
          <li>Review the Supported Web Browser list:
            <ul>
              <li>Windows
                <ul>
                  <li>Chrome 8.0</li>
                  <li>Firefox 10</li>
                  <li>Edge 80</li>
                </ul>
              </li>
              <li>MAC
                <ul>
                  <li>Chrome 8.0</li>
                  <li>Firefox 10</li>
                  <li>Safari 12</li>
                </ul>
              </li>
              <li>iPhone/iPad
                <ul>
                  <li>Chrome 8.0</li>
                  <li>Safari 12</li>
                </ul>
              </li>
              <li>Android
                <ul>
                  <li>Chrome 8.0</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>Restart your browser and test the website.</li>
          <li>Clear your browser’s cookies and cache, then restart your browser and test the website.</li>
          <li>Restart your device and test the website.</li>
          <li>Try an alternate browser to see if the issue persists.</li>
        </ul>
        <p><span class="clickableText"><a id="backToTopQ5" (click)="onClickScroll('top')">Back to Top</a></span></p>
        <p id='q6'><strong>Problem with accessing website from other devices e.g., cell phone or tablet</strong></p>
        <p>Try the following troubleshooting steps:</p>
        <ul>
          <li>Review the Supported Web Browser list:
            <ul>
              <li>Windows
                <ul>
                  <li>Chrome 8.0</li>
                  <li>Firefox 10</li>
                  <li>Edge 80</li>
                </ul>
              </li>
              <li>MAC
                <ul>
                  <li>Chrome 8.0</li>
                  <li>Firefox 10</li>
                  <li>Safari 12</li>
                </ul>
              </li>
              <li>iPhone/iPad
                <ul>
                  <li>Chrome 8.0</li>
                  <li>Safari 12</li>
                </ul>
              </li>
              <li>Android
                <ul>
                  <li>Chrome 8.0</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>Verify your device’s network connection and validate you can access other sites.</li>
          <li>Restart your device and try accessing the website.</li>
        </ul>
        <p><span class="clickableText"><a id="backToTopQ6" (click)="onClickScroll('top')">Back to Top</a></span></p>
        <p><strong>Other questions?</strong></p>
        <p>If you're still having trouble, please submit your email inquiry for review.</p>
        <p>If you have additional Technical questions about the Familial Hypercholesterolemia website, please email <a id="rstConCareSupportLink" href="mailto:RSTCONCARESUPPORT@mayo.edu" class="clickableText_email">RSTCONCARESUPPORT@mayo.edu</a> to submit your inquiry to the Mayo support staff.</p>
        <p>If you have additional Non-Technical questions about the Familial Hypercholesterolemia website, please email <a id="rstKulloLabLink" href="mailto:RSTKULLOLAB@mayo.edu" class="clickableText_email">RSTKULLOLAB@mayo.edu</a> to submit your inquiry to the Mayo medical staff.</p>
        <p>Support hours are 8 am - 5 pm Central time, Monday through Friday, for assistance.</p>
        <p>Customers can expect a response between 2-4 business days.</p>
        </article>
      </div>
    </div>
  </div>
</div>
</main>
